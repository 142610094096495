import Status from "@/services/status";

export default {
  data () {
    return {
      itemStatus: null,
    };
  },
  watch: {
    async "$store.state.user" () {
      await this.getUserStatus();
    },
  },
  mounted () {
    this.$root.$on("status-updated", async (item) => {
      if (!this.item) { return; }
      if (item.item_id !== this.item._id) { return; }
      await this.getUserStatus();
    });
  },
  methods: {
    async getUserStatus () {
      if (this.$store.state.user === null) { return null; }
      if (!this.item) { return null; }

      const statusAPI = new Status(this.$axios);
      statusAPI.authUser(this.$store.state.user);
      if (this.item && this.itemType) {
        this.itemStatus = await statusAPI.getUserStatus(this.item._id, this.itemType);
      }
    },
    getRadioButtonLabel (status, itemType) {
      if (["movie", "tv", "season", "episode"].includes(itemType)) {
        if (status === 0) {
          return "Want to Watch";
        } else if (status === 1) {
          return "Watching";
        } else if (status === 2) {
          return "Watched";
        } else if (status === 3) {
          return "Re-Watched";
        } else if (status === 4) {
          return "Shelved";
        } else if (status === 5) {
          return "Abandoned";
        }
      }
      if (itemType === "game") {
        if (status === 0) {
          return "Want to Play";
        } else if (status === 1) {
          return "Playing";
        } else if (status === 2) {
          return "Played";
        } else if (status === 3) {
          return "Played Again";
        } else if (status === 4) {
          return "Shelved";
        } else if (status === 5) {
          return "Abandoned";
        }
      }
      if (itemType === "book") {
        if (status === 0) {
          return "Want to Read";
        } else if (status === 1) {
          return "Reading";
        } else if (status === 2) {
          return "Already Read";
        } else if (status === 3) {
          return "Read Again";
        } else if (status === 4) {
          return "Shelved";
        } else if (status === 5) {
          return "Abandoned";
        }
      }
    },
    getRadioButtonSubLabel (status, itemType) {
      if (["movie", "tv", "season", "episode"].includes(itemType)) {
        if (status === 0) {
          return "Add to your watchlist";
        } else if (status === 1) {
          return "Currently watching";
        } else if (status === 2) {
          return "Already watched";
        } else if (status === 3) {
          return "Watched again more than once";
        } else if (status === 4) {
          return "Not interested anymore";
        } else if (status === 5) {
          return "Forgot to Finish";
        }
      }
      if (itemType === "game") {
        if (status === 0) {
          return "Add to your watchlist";
        } else if (status === 1) {
          return "Currently playing";
        } else if (status === 2) {
          return "Already played";
        } else if (status === 3) {
          return "Played again more than once";
        } else if (status === 4) {
          return "Not interested anymore";
        } else if (status === 5) {
          return "Forgot to Finish";
        }
      }
      if (itemType === "book") {
        if (status === 0) {
          return "Add to your watchlist";
        } else if (status === 1) {
          return "Currently reading";
        } else if (status === 2) {
          return "Already read";
        } else if (status === 3) {
          return "Read again more than once";
        } else if (status === 4) {
          return "Not interested anymore";
        } else if (status === 5) {
          return "Forgot to Finish";
        }
      }
    },
  },
};
