<template>
  <v-dialog
    v-model="dialog"
    width="400"
    close-on-content-click
  >
    <v-card class="rounded-xl">
      <v-card-title>
        Set Status
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="itemStatus">
                <v-radio value="-1" @click="setStatus">
                  <template #label>
                    <div class="d-block">
                      <div class="white--text">
                        Unset
                      </div>
                      <div class="pl-2">
                        <small class="grey--text text--lighten-1">
                          Totally remove status from this item.
                        </small>
                      </div>
                    </div>
                  </template>
                </v-radio>
                <v-divider class="mb-4" />
                <v-radio value="0" @click="setStatus">
                  <template #label>
                    <div class="d-block">
                      <div class="white--text">
                        {{ getRadioButtonLabel(0, itemType) }}
                      </div>
                      <div class="pl-2">
                        <small class="grey--text text--lighten-1">
                          {{ getRadioButtonSubLabel(0, itemType) }}
                        </small>
                      </div>
                    </div>
                  </template>
                </v-radio>
                <v-divider class="mb-4" />
                <v-radio value="1" @click="setStatus">
                  <template #label>
                    <div class="d-block">
                      <div class="white--text">
                        {{ getRadioButtonLabel(1, itemType) }}
                      </div>
                      <div class="pl-2">
                        <small class="grey--text text--lighten-1">
                          {{ getRadioButtonSubLabel(1, itemType) }}
                        </small>
                      </div>
                    </div>
                  </template>
                </v-radio>
                <v-divider class="mb-4" />
                <v-radio value="2" @click="setStatus">
                  <template #label>
                    <div class="d-block">
                      <div class="white--text">
                        {{ getRadioButtonLabel(2, itemType) }}
                      </div>
                      <div class="pl-2">
                        <small class="grey--text text--lighten-1">
                          {{ getRadioButtonSubLabel(2, itemType) }}
                        </small>
                      </div>
                    </div>
                  </template>
                </v-radio>
                <v-divider class="mb-4" />
                <v-radio value="3" @click="setStatus">
                  <template #label>
                    <div class="d-block">
                      <div class="white--text">
                        {{ getRadioButtonLabel(3, itemType) }}
                      </div>
                      <div class="pl-2">
                        <small class="grey--text text--lighten-1">
                          {{ getRadioButtonSubLabel(3, itemType) }}
                        </small>
                      </div>
                    </div>
                  </template>
                </v-radio>
                <v-divider class="mb-4" />
                <v-radio value="4" @click="setStatus">
                  <template #label>
                    <div class="d-block">
                      <div class="white--text">
                        {{ getRadioButtonLabel(4, itemType) }}
                      </div>
                      <div class="pl-2">
                        <small class="grey--text text--lighten-1">
                          {{ getRadioButtonSubLabel(4, itemType) }}
                        </small>
                      </div>
                    </div>
                  </template>
                </v-radio>
                <v-divider class="mb-4" />
                <v-radio value="5" @click="setStatus">
                  <template #label>
                    <div class="d-block">
                      <div class="white--text">
                        {{ getRadioButtonLabel(5, itemType) }}
                      </div>
                      <div class="pl-2">
                        <small class="grey--text text--lighten-1">
                          {{ getRadioButtonSubLabel(5, itemType) }}
                        </small>
                      </div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Status from "@/services/status";
import itemStatus from "@/mixins/status";

export default {
  name: "Status",
  mixins: [itemStatus],
  data () {
    return {
      dialog: false,
      item: {},
      itemType: "",
      status: null,
      itemStatus: null,
    };
  },
  mounted () {
    this.$root.$on("openStatusDialog", async (item, itemType) => {
      this.item = item;
      this.itemType = itemType;

      const statusAPI = new Status(this.$axios);
      statusAPI.authUser(this.$store.state.user);
      const response = await statusAPI.getUserStatus(this.item._id);
      this.itemStatus = response ? String(response.status) : null;

      this.dialog = true;
    });
  },
  methods: {
    async setStatus () {
      const token = this.$store.state.user.token;
      const statusAPI = new Status(this.$axios, {
        Authorization: `Bearer ${token}`,
      });

      const params = {
        item_id: this.item._id,
        item_type: this.itemType,
        status: this.itemStatus,
      };
      await statusAPI.upsertItemStatus(params);
      this.$toast.info("Status updated");
      this.$root.$emit("status-updated", {
        item_id: this.item._id,
        item_type: this.itemType,
      });
      this.dialog = false;
    },
  },
};
</script>
