import Base from "./base";

export default class Status extends Base {
  async upsertItemStatus (params) {
    return await this.makePostRequest("/statuses", params);
  }

  async getUserStatus (itemId) {
    return await this.makeGetRequest("/statuses", { item_id: itemId });
  }
}
